import React, { Component } from "react";
import { connect } from "react-redux";
import LogRocket from "logrocket";
import { Collapse, Button, Icon, Modal, Input, Checkbox } from "antd";
import { isEqual, isEmpty } from "lodash";
import { bindActionCreators } from "redux";
import axios from "axios";
import CustomScrollbars from "util/CustomScrollbars";
import { convertFromStep, convertToStep } from "util/helpers";
import SidebarLogo from "./SidebarLogo";
import texts from "config/texts.json";
import { getFromStorage, setInStorage } from "localStorageService";
import Auxiliary from "util/Auxiliary";
import {
    sendUserPreferences,
    sendEmailNotification,
    submitUserEmail
} from "requestService";
import Sliders from "../../components/Sliders";
import { iaText } from "config/iaText";
import { dataConfig } from "config/dataConfig";
import {
    NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
    NAV_STYLE_NO_HEADER_MINI_SIDEBAR
} from "../../constants/ThemeSetting";

import {
    getAllCauses,
    storeSelectedCauses,
    getTickersData,
    getPortfolioData,
    storeSelectedCause,
    reset,
    setGeoLocation
} from "./actions";


const dbKey = getFromStorage("db") || "x";
const Panel = Collapse.Panel;
const confirm = Modal.info;
const siteColor = dataConfig.siteColor;
const defaultCauseID = iaText()["defaultCauseID"];
const dataConfigString = JSON.stringify(dataConfig || {});

const defaultEnabledCause =
    defaultCauseID === "climateChange"
        ? {
              climateChange: {
                  category: iaText()[
                      "cause." + defaultCauseID + ".categoryLookup"
                  ],
                  step: 3
              }
          }
        : {
              women: {
                  category: iaText()[
                      "cause." + defaultCauseID + ".categoryLookup"
                  ],
                  step: 3
              }
          };

class SidebarContent extends Component {
    constructor(props) {
        super(props);
        const stepsFromStorage = getFromStorage("steps");
        this.state = {
            causes: [],
            step: stepsFromStorage,
            firstLoad: true,
            openCallToActionModal: false,
            checkboxClicked: true,
            userEmail: "",
            isEmailValid: false,
            hasGeoDetails: false
        };
        this.myInputRefEmail = React.createRef();
        this.myInputRefFirstName = React.createRef();
        this.myInputRefLastName = React.createRef();
        this.myInputRefPhone = React.createRef();

        this.getGeoLocation = this.getGeoLocation.bind(this);
    }

    sendEmail = () => {
        var userAction = getFromStorage("userAction") ||
            getFromStorage("actions") || [""];
        userAction = userAction + "⚪️";
        setInStorage("userAction", userAction);

        const geoIp = this.props.sidebar.geo_ip;
        const previousSeletedSteps = getFromStorage("steps") || {};
        const customerId = getFromStorage("customerId");
        const apiCount = getFromStorage("apiCount") || [""];
        const includedFromStorage = getFromStorage("included") || [""];
        const excludedFromStorage = getFromStorage("excluded") || [""];
        const userType = getFromStorage("userType") || "";
        const userPreset = getFromStorage("userPreset") || "";
        const userEmail = getFromStorage("userEmail") || "";
        const userFirstName = getFromStorage("userFirstName") || "";
        const userLastName = getFromStorage("userLastName") || "";
        const userPhone = getFromStorage("userPhone") || "";
        const userFullName = getFromStorage("userFullName") || "";
        console.log("📩 1️⃣ 🌎 ⚪️ sidebar sendEmail...", geoIp);
        console.log("📩 1️⃣ state", this.state);  
        // debugger;
        sendEmailNotification({
            ...geoIp,
            appState: JSON.stringify(this.state),
            steps: JSON.stringify(previousSeletedSteps),
            customerId,
            userAction,
            apiCount,
            userType,
            userPreset,
            included: includedFromStorage.join(","),
            excluded: excludedFromStorage.join(","),
            userFirstName,
            userLastName,
            userFullName,
            userPhone,
            userEmail,
            dataConfig: dataConfigString
        });
    };

    getGeoLocation = () => {
        const self = this;
        // debugger;
        axios
            .get("https://ssl.geoplugin.net/json.gp?k=4cc0e19c1c358733")
            .then(async response => {
                // console.log('🌎 getGeoL 0 response 🔑🔑🔑🔑', response.data);
                await self.props.setGeoLocation(response.data);
                await self.setState({
                    hasGeoDetails: true
                });
                // console.log('🌎 getGeoL 1 sending... 🔑🔑🔑🔑', response.data);
                await self.sendEmail(response.data);
                console.log('🌎 getGeoL 2 after sending; state:', this.state);
            })
            .catch(error => {
                console.log('🌎 getGeoL 3 ❌ error catch:', error);
                setTimeout(() => self.getGeoLocation(), dataConfig.geoDelay);
            });
    };

    componentWillMount() {
        this.getCauses();
    }

    getCauses = () => {
        const stepsFromStorage = getFromStorage("steps");
        const hasNoValues = isEmpty(stepsFromStorage);
        if (!hasNoValues) {
            this.setState({
                ...this.state,
                step: stepsFromStorage
            });
        }
        this.props.getAllCauses();
    };

    onCloseCallToAction = () => {
        this.setState({
            openCallToActionModal: false
        });
    };

    toogleCheckbox = () => {
        this.setState({
            checkboxClicked: !this.state.checkboxClicked
        });
    };

    onSaveCallToAction = userType => {
        var userAction = getFromStorage("userAction") ||
            getFromStorage("actions") || [""];
        userAction = userAction + "✅z"; // include z to trigger zap in geoEmail.php 
        setInStorage("userAction", userAction);

        const geoIp = this.props.sidebar.geo_ip;
        const stepsFromLocalStorage = getFromStorage("steps") || {};
        const customerId = getFromStorage("customerId");
        const apiCount = getFromStorage("apiCount") || [""];
        const included = getFromStorage("included") || [""];
        const excluded = getFromStorage("excluded") || [""];

        var userFirstName = this.myInputRefFirstName.current.state.value || '';
        var userLastName = this.myInputRefLastName.current.state.value || '';
        var userFullName = userFirstName ? (userLastName ? `${userFirstName} ${userLastName}` : userFirstName) : '';
        var userNameLog = window.location.hostname + '·' + (geoIp.city || 'cccc');
        const userEmail = this.myInputRefEmail.current.state.value;
        const userPhone = this.myInputRefPhone.current.state.value || '';

        // const dataCfg = JSON.stringify(dataConfig || {});


        console.log("📩 2️⃣ onSaveCallToAction", userAction, userFirstName, userLastName, userEmail, userPhone, userType);
        if (!dataConfig.skipLogRocket) {
            console.log('🚀.id ', geoIp, '  -  ', geoIp.ip, ' ? ', userFullName, userEmail, userType, userPhone);
            LogRocket.identify(geoIp.ip, {
                name: userNameLog,
                email: userEmail,
                tel: userPhone,
                state: geoIp.regionCode,
                ip: geoIp.ip,
                cityGeo: geoIp.city,
                stateGeo: geoIp.regionCode,
                navPlatform: dataConfig.navPlatform,
                websiteDomain: window.location.hostname
            });
        } else {
            console.log('🚀.id ⛔️ skipLogR:', dataConfig.skipLogRocket, userFullName, userEmail, userType, userPhone);
        }
        console.log("📩 2️⃣ onSaveCallToAction subs...", this.state);    
        submitUserEmail({
            ...geoIp,
            steps: JSON.stringify(stepsFromLocalStorage),
            customerId,
            userAction,
            apiCount,
            included: included.join(","),
            excluded: excluded.join(","),
            userFullName,
            userLastName,
            userFirstName,
            userEmail,
            userType,
            userPhone,
            dataConfig: dataConfigString
        });

        this.setState({
            openCallToActionModal: false
        });
        setInStorage("emailSubmitted", true);
        setInStorage("userEmail", userEmail);
        setInStorage("userFirstName", userFirstName);
        setInStorage("userLastName", userLastName);
        setInStorage("userFullName", userFullName);
        setInStorage("userType", userType);
        setInStorage("userPhone", userPhone);
        this.chatBotLaunch();
    };

    chatBotLaunch = () => {
        let userType = getFromStorage("userType");

        let artiDelay = /(labs)/.test(window.location.hostname)
            ? 180000
            : 20000;
        console.log("🤖⌚️:", userType, artiDelay);

    };

    modalShowPerf = ev => {
        const textList = iaText();
        const text = textList["confirmModal.modalShowPerf.html"];
        const modalTitle = textList["confirmModal.modalShowPerf.title"];
        const iconUrl = textList["confirmModal.modalShowPerf.icon"];
        confirm({
            title: modalTitle,
            icon: <img src={iconUrl} alt="" />,
            maskClosable: true,
            content: (
                <div
                    dangerouslySetInnerHTML={{
                        __html: "<hr/>" + text
                    }}
                />
            ),
            onOk() {}
        });
    };

    async componentDidMount() {
        const alreadyOpened = getFromStorage("modalOpened") || "";
        const hasCustomerId = getFromStorage("customerId") || "";
        if (!hasCustomerId) {
            const date = new Date();
            setInStorage("customerId", date);
        }
        var emailTimeOut = dataConfig.emailTimeOut * (alreadyOpened ? 0.2 : 1);
        setTimeout(() => {
            const isEmailSubmitted = getFromStorage("emailSubmitted");
            let userEmailCheck = getFromStorage("userEmail") || "·";
            if (!isEmailSubmitted || userEmailCheck==='xxx') {
                this.setState({
                    openCallToActionModal: true
                });
            }
        }, emailTimeOut);
        console.log("📩 componentDidMount");
        // window.addEventListener("beforeunload", ev => {
        //     ev.preventDefault();
        //     const geoIp = this.props.sidebar.geo_ip;
        //     const stepsFromLocalStorage = getFromStorage("steps") || {};
        //     const customerId = getFromStorage("customerId");
        //     const included = getFromStorage("included") || [""];
        //     const excluded = getFromStorage("excluded") || [""];
        //     const userType = getFromStorage("userType") || "·";
        //     const email = getFromStorage("userEmail") || "·";
        //     const name = getFromStorage("userFullName") || "·";
        //     console.log("📩 📩 📩 ❌ beforeunload");
        //     sendUserPreferences({
        //         ...geoIp,
        //         steps: JSON.stringify(stepsFromLocalStorage),
        //         customerId,
        //         userAction: "❌",
        //         userType,
        //         included: included.join(","),
        //         excluded: excluded.join(","),
        //         name,
        //         email
        //     });
        //     const confirmationMessage = "o/";

        //     (ev || window.event).returnValue = confirmationMessage; //Gecko + IE
        //     return confirmationMessage;
        // });

        window.addEventListener("unload", () => {
            var userAction = getFromStorage("userAction") ||
                getFromStorage("actions") || [""];
            userAction = userAction + "↗️";
            setInStorage("userAction", userAction);

            const geoIp = this.props.sidebar.geo_ip;
            const stepsFromLocalStorage = getFromStorage("steps") || {};
            const customerId = getFromStorage("customerId") || [""];
            const included = getFromStorage("included") || [""];
            const excluded = getFromStorage("excluded") || [""];
            const userType = getFromStorage("userType") || "·";
            const apiCount = getFromStorage("apiCount") || [""];
            const userEmail = getFromStorage("userEmail") || "·";
            const userFullName = getFromStorage("userFullName") || "·";
            //const userFirstName = getFromStorage("userFirstName") || "·";
            //const userLastName = getFromStorage("userLastName") || "·";
            const userPhone = getFromStorage("userPhone") || "·";
            console.log("📩 📩 📩 ↗️ unload: ", customerId);
            sendUserPreferences({
                ...geoIp,
                steps: JSON.stringify(stepsFromLocalStorage),
                customerId,
                userAction: "❌unload❌" + userAction,
                apiCount,
                userType,
                included: included.join(","),
                excluded: excluded.join(","),
                userFullName,
                userEmail,
                userPhone,
                dataConfig: dataConfigString
            });
        });

        // setting up this to allow dynamic preset
        const defaultCauseID = iaText()["defaultCauseID"];
        const stepsFromStorage = getFromStorage("steps") || {};
        const getDefaultCause =
            (stepsFromStorage && stepsFromStorage.defaultCauseID) || {};
        const defaultCauseSetting =
            (getDefaultCause && getDefaultCause.step) || 3;
        const selectedCauseFromStorage = getFromStorage("selectedCauses") || {};

        await this.setCause(defaultCauseSetting, {
            causeCategory: iaText()[
                "cause." + defaultCauseID + ".categoryLookup"
            ],
            causeEnabled: true,
            causeId: defaultCauseID,
            causeLabel: iaText()["cause." + defaultCauseID + ".title"],
            causeVisible: true,
            causeWeight: 75
        });

        await Object.keys(selectedCauseFromStorage).forEach(key => {
            const cause = selectedCauseFromStorage[key];
            this.setCause(convertToStep(cause.causeWeight), cause);
        });

        await this.getGeoLocation();
    }

    componentWillReceiveProps(nextProps) {
        const stepsFromStorage =
            getFromStorage("steps") || nextProps.sidebar.presets;
        const currentSteps = this.state.step;

        const presetsLoaded = getFromStorage("presetsLoaded");
        const nextPresetCauses = nextProps.sidebar.presets;

        const sameSteps = isEqual(currentSteps, stepsFromStorage);
        if (!sameSteps) {
            this.setState({
                ...this.state,
                step: stepsFromStorage
            });
        }
        if (!presetsLoaded && !isEmpty(nextPresetCauses)) {
            Object.keys(nextPresetCauses).forEach(key => {
                const cause = nextPresetCauses[key];
                this.setCause(convertToStep(cause.causeWeight), cause);
            });
            const currentLocalStorageSteps = getFromStorage("steps");
            setInStorage("steps", {
                ...currentLocalStorageSteps,
                ...nextProps.sidebar.presetsToSteps
            });
            setInStorage("presetsLoaded", true);
        }

        if (nextProps.sidebar.resetApp) {
            this.resetApp();
        }
    }

    componentDidUpdate(prevProps) {
        const { tickerCauses } = this.props.sidebar;
        const hasNewTickers = !isEqual(
            tickerCauses,
            prevProps.sidebar.tickerCauses
        );
        if (this.state.firstLoad || hasNewTickers) {
            setInStorage("returns", tickerCauses);
            this.props.getTickersData(tickerCauses);
            this.setState({
                firstLoad: false
            });
        }
    }

    getPortfolio = () => {
        const { tickerCauses } = this.props.sidebar;
        this.props.getTickersData(tickerCauses);
    };

    getNoHeaderClass = navStyle => {
        if (
            navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
            navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
        ) {
            return "gx-no-header-notifications";
        }
        return "";
    };

    getNavStyleSubMenuClass = navStyle => {
        if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
            return "gx-no-header-submenu-popup";
        }
        return "";
    };

    setCauseToStore = cause => {
        this.props.storeSelectedCause(cause);
        this.props.storeSelectedCauses(cause);
    };

    setCause = async (step, cause, triggerApi) => {
        cause.causeWeight = convertFromStep(step);

        this.setState({
            ...this.state,
            step: {
                ...this.state.step,
                [cause.causeId]: {
                    step,
                    category: cause.causeCategory
                }
            }
        });

        const updateCause = {
            ...this.state.step,
            [cause.causeId]: {
                step,
                category: cause.causeCategory
            }
        };

        await setInStorage("steps", updateCause);

        if (!triggerApi) {
            await this.setCauseToStore(cause);
        }
    };

    onMouseUp = (step, cause, triggerApi) => {
        this.setCause(step, cause, triggerApi);
    };

    resetApp = async () => {
        var userAction = getFromStorage("userAction") ||
            getFromStorage("actions") || [""];
        userAction = userAction + "♻️";
        setInStorage("userAction", userAction);

        const geoIp = this.props.sidebar.geo_ip;
        const stepsFromLocalStorage = getFromStorage("steps") || {};
        const customerId = getFromStorage("customerId") || [""];
        const apiCount = getFromStorage("apiCount") || [""];
        const included = getFromStorage("included") || [""];
        const excluded = getFromStorage("excluded") || [""];
        const userType = getFromStorage("userType") || "·";
        const userEmail = getFromStorage("userEmail") || "·";
        const userFirstName = getFromStorage("userFirstName") || "·";
        const userFullName = getFromStorage("userFullName") || "·";
        const userLastName = getFromStorage("userLastName") || "";
        const userPhone = getFromStorage("userPhone") || "";
        console.log("📩 ♻️ 📩 📩  resetApp: ", userAction);
        sendUserPreferences({
            ...geoIp,
            steps: JSON.stringify(stepsFromLocalStorage),
            customerId,
            userAction,
            apiCount,
            userType,
            included: included.join(","),
            excluded: excluded.join(","),
            userFullName,
            userFirstName,
            userLastName,
            userEmail,
            userPhone,
            dataConfig: dataConfigString
        });

        await this.props.reset();
        await this.setState({
            ...this.state,
            step: {}
        });

        await setInStorage("steps", defaultEnabledCause);

        const defaultCauseID = iaText()["defaultCauseID"];
        await this.setCause(3, {
            causeCategory: iaText()[
                "cause." + defaultCauseID + ".categoryLookup"
            ],
            causeEnabled: true,
            causeId: defaultCauseID,
            causeLabel: iaText()["cause." + defaultCauseID + ".title"],
            causeVisible: true,
            causeWeight: 75
        });
        await this.getCauses();

        const tickerCauses = getFromStorage("returns");
        await this.props.getTickersData(tickerCauses);
        await this.getPortfolio();
    };

    showConfirm = (ev, cause) => {
        const textList = iaText();
        const text =
            (window.innerWidth <= 414 ? "" : "<hr/>") +
            (textList["cards." + cause.causeId] || "");
        const iconUrl = texts["cause." + cause.causeId + ".icon"]
            ? texts["cause." + cause.causeId + ".icon"]
            : texts["cause.placeHolder.icon"];
        confirm({
            title: cause.causeLabel,
            icon: <img src={iconUrl} alt={cause.causeLabel} />,
            maskClosable: true,
            content: (
                <div
                    dangerouslySetInnerHTML={{
                        __html: text
                    }}
                />
            ),
            onOk() {}
        });
    };

    validateEmail = ev => {
        const emailRegex = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const isEmailValid =
            (ev.target.value === "...." ||
                ev.target.value === "\\\\" ||
                ev.target.value === "777888" ||
                ev.target.value === "vvvjjj" ||
                /(test|local|j.alpha)/.test(window.location.hostname) ||
                emailRegex.test(String(ev.target.value).toLowerCase())) &&
            !/(spam4|mailinator|sharklaser|grr\.la)/.test(
                String(ev.target.value).toLowerCase()
            );
        this.setState({
            isEmailValid
        });
    };

    fillPhone(phoneInput) {
        //  let them have the leading 1 temporarily
        phoneInput = this.myInputRefPhone;
        if (phoneInput === '1') {
          phoneInput = phoneInput + '-';
        }

        //  do not allow invalid numbers to continue / if all numbers were copied in:
        if (phoneInput.length >= 5) {
          // console.log('>=5');
          phoneInput = phoneInput.replace(/\D/g, '');
          while (phoneInput.charAt(0) === '1' || phoneInput.charAt(0) === '0') {
            phoneInput = phoneInput.substring(1);
          }
          phoneInput = phoneInput.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
          phoneInput = phoneInput.replace(/(\d{3})(\d{3})(\d{3})/, '$1-$2-$3');
          phoneInput = phoneInput.replace(/(\d{3})(\d{3})(\d{2})/, '$1-$2-$3');
          phoneInput = phoneInput.replace(/(\d{3})(\d{3})(\d{1})/, '$1-$2-$3');
          phoneInput = phoneInput.replace(/(\d{3})(\d{3})/, '$1-$2');
          phoneInput = phoneInput.replace(/(\d{3})(\d{2})/, '$1-$2');
        }

        if (phoneInput.length === 3 && phoneInput === phoneInput.replace(/\D/g, '')) {
          phoneInput = phoneInput + '-';
        }
        if (phoneInput.length === 4 && phoneInput === phoneInput.replace(/\D/g, '')) {
          phoneInput = phoneInput.replace(/(\d{3})(\d{1})/, '$1-$2');
        }
        return phoneInput;
    }

    render() {
        const { sidebar } = this.props;
        let headerNameTop;
        if (dataConfig.SDG) {
            headerNameTop = "UN Sustainable Dev. Goals";
        } else if (dataConfig.VJ) {
            headerNameTop = "Custom ESG";
        } else if (dataConfig.Veg) {
            headerNameTop = "Popular Causes 🌱";
        } else if (dataConfig.Equ) {
            headerNameTop = "Popular Causes";
        } else {
            headerNameTop = "Popular Causes";
        }
        const text = iaText();
        const selectedCauses = getFromStorage("selectedCauses") || {};
        const isButtonDisabled =
            this.state.checkboxClicked && this.state.isEmailValid;
        let userEmail = getFromStorage("userEmail") || '';
        // let userFirstName = getFromStorage("userFirstName") || "";
        // let userLastName = getFromStorage("userLastName") || "";
        // let userPhone = getFromStorage("userPhone") || "";

        let aboutHtml = "https://ImpactLabs.com/team.html#x&email=".concat(userEmail);
        let pressHtml ="https://ImpactLabs.com/press.html#x&email=".concat(userEmail);
        let solutionsHtml = "https://ImpactAdvisor.com/products.html#x&email=".concat(userEmail);
        let taxHtml = "https://tax.ImpactLabs.com/#x&email=".concat(userEmail);
        return (
            <Auxiliary>
                <SidebarLogo />
                <div className="gx-sidebar-content sliders-wrapper sidebarBackgroundColor">
                    <CustomScrollbars className="gx-layout-sider-scrollbar">
                        <Collapse
                            className="causesSlider"
                            accordion
                            defaultActiveKey={["0"]}
                        >
                            {sidebar.popularCauses.length > 0 && (
                                <Panel header={headerNameTop}>
                                    {sidebar.popularCauses.map(
                                        (popularCause, index) => {
                                            return (
                                                <React.Fragment
                                                    key={`${index}_selected_slider`}
                                                >
                                                    <div
                                                        className="sliderTitle"
                                                        onClick={ev =>
                                                            this.showConfirm(
                                                                ev,
                                                                popularCause
                                                            )
                                                        }
                                                    >
                                                        <div className="labelForSlider">
                                                            {popularCause.causeLabel
                                                                .replace(
                                                                    "🇺🇳",
                                                                    dataConfig.emojiSDG
                                                                )
                                                                .replace(
                                                                    "SDG·",
                                                                    dataConfig.emojiSDGdot +
                                                                        " "
                                                                )}
                                                        </div>
                                                        <div className="infoIcon">
                                                            <Icon type="info-circle" />
                                                        </div>
                                                    </div>
                                                    <Sliders
                                                        step={this.state.step}
                                                        cause={popularCause}
                                                        onMouseUp={(
                                                            step,
                                                            popularCause,
                                                            triggerApi
                                                        ) =>
                                                            this.onMouseUp(
                                                                step,
                                                                popularCause,
                                                                triggerApi
                                                            )
                                                        }
                                                    />
                                                </React.Fragment>
                                            );
                                        }
                                    )}
                                </Panel>
                            )}
                            {sidebar.causes &&
                                Object.keys(sidebar.causes).map(
                                    (category, index) => {
                                        const causes = sidebar.causes[category];
                                        // const title = `causeGroup.${
                                        //     Object.values(
                                        //         sidebar.causes[category]
                                        //     )[0].causeCategory
                                        // }.title`;

                                        const getFirstObject = Object.values(
                                            sidebar.causes[category]
                                        )[0];
                                        const categoryObject = sidebar.categories.find(
                                            category =>
                                                category.name ===
                                                getFirstObject.causeCategory
                                        );
                                        const title = categoryObject.label;
                                        const hasEnabledCauses = Object.values(
                                            causes
                                        ).filter(cause => cause.causeEnabled);

                                        const causeCategory = Object.values(
                                            causes
                                        )[0].causeCategory;
                                        const isCollapsable = sidebar.collapsedCategories.indexOf(
                                            causeCategory
                                        );

                                        return (
                                            hasEnabledCauses.length > 0 &&
                                            isCollapsable === -1 && (
                                                <Panel
                                                    header={title}
                                                    key={index + 1}
                                                >
                                                    {Object.keys(causes).map(
                                                        (item, indx) => {
                                                            const causeItem =
                                                                causes[item];
                                                            return (
                                                                <div
                                                                    key={`${indx}_selected_slider`}
                                                                    style={{
                                                                        display: causeItem.causeEnabled
                                                                            ? "block"
                                                                            : "none"
                                                                    }}
                                                                >
                                                                    <div
                                                                        className="sliderTitle"
                                                                        onClick={ev =>
                                                                            this.showConfirm(
                                                                                ev,
                                                                                causeItem
                                                                            )
                                                                        }
                                                                    >
                                                                        <div className="labelForSlider">
                                                                            {causeItem.causeLabel
                                                                                .replace(
                                                                                    "🇺🇳",
                                                                                    dataConfig.emojiSDG
                                                                                )
                                                                                .replace(
                                                                                    "SDG·",
                                                                                    dataConfig.emojiSDGdot +
                                                                                        " "
                                                                                )}
                                                                        </div>
                                                                        <div className="infoIcon">
                                                                            <Icon type="info-circle" />
                                                                        </div>
                                                                    </div>
                                                                    <Sliders
                                                                        step={
                                                                            this
                                                                                .state
                                                                                .step
                                                                        }
                                                                        cause={
                                                                            causeItem
                                                                        }
                                                                        onMouseUp={(
                                                                            step,
                                                                            selectedCause,
                                                                            triggerApi
                                                                        ) =>
                                                                            this.onMouseUp(
                                                                                step,
                                                                                selectedCause,
                                                                                triggerApi
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </Panel>
                                            )
                                        );
                                    }
                                )}
                            <Panel header={"More Causes"}>
                                <Collapse
                                    className="causesSlider otherCategories"
                                    accordion
                                    defaultActiveKey={["0"]}
                                >
                                    {sidebar.causes &&
                                        Object.keys(sidebar.causes).map(
                                            (category, index) => {
                                                const causes =
                                                    sidebar.causes[category];
                                                // const title = `causeGroup.${
                                                //     Object.values(
                                                //         sidebar.causes[category]
                                                //     )[0].causeCategory
                                                // }.title`;

                                                const getFirstObject = Object.values(
                                                    sidebar.causes[category]
                                                )[0];
                                                const categoryObject = sidebar.categories.find(
                                                    category =>
                                                        category.name ===
                                                        getFirstObject.causeCategory
                                                );
                                                const title =
                                                    categoryObject.label;

                                                const causeCategory = Object.values(
                                                    causes
                                                )[0].causeCategory;
                                                const isCollapsable = sidebar.collapsedCategories.indexOf(
                                                    causeCategory
                                                );

                                                if (isCollapsable !== -1) {
                                                    return (
                                                        <Panel
                                                            header={title}
                                                            key={`other_${index +
                                                                1}`}
                                                        >
                                                            {Object.keys(
                                                                causes
                                                            ).map(
                                                                (
                                                                    item,
                                                                    indx
                                                                ) => {
                                                                    const causeItem =
                                                                        causes[
                                                                            item
                                                                        ];
                                                                    return (
                                                                        <div
                                                                            key={`${indx}_selected_slider`}
                                                                            style={{
                                                                                display: causeItem.causeEnabled
                                                                                    ? "block"
                                                                                    : "none"
                                                                            }}
                                                                        >
                                                                            <div
                                                                                className="sliderTitle"
                                                                                onClick={ev =>
                                                                                    this.showConfirm(
                                                                                        ev,
                                                                                        causeItem
                                                                                    )
                                                                                }
                                                                            >
                                                                                <div className="labelForSlider">
                                                                                    {causeItem.causeLabel
                                                                                        .replace(
                                                                                            "🇺🇳",
                                                                                            dataConfig.emojiSDG
                                                                                        )
                                                                                        .replace(
                                                                                            "SDG·",
                                                                                            dataConfig.emojiSDGdot +
                                                                                                " "
                                                                                        )}
                                                                                </div>
                                                                                <div className="infoIcon">
                                                                                    <Icon type="info-circle" />
                                                                                </div>
                                                                            </div>
                                                                            <Sliders
                                                                                step={
                                                                                    this
                                                                                        .state
                                                                                        .step
                                                                                }
                                                                                cause={
                                                                                    causeItem
                                                                                }
                                                                                onMouseUp={(
                                                                                    step,
                                                                                    selectedCause,
                                                                                    triggerApi
                                                                                ) =>
                                                                                    this.onMouseUp(
                                                                                        step,
                                                                                        selectedCause,
                                                                                        triggerApi
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                        </Panel>
                                                    );
                                                }
                                                return "";
                                            }
                                        )}
                                </Collapse>
                            </Panel>
                            {Object.values(selectedCauses).length > 1 && (
                                <Panel header="Your Selections">
                                    {Object.values(selectedCauses).map(
                                        (selected, index) => {
                                            return (
                                                <React.Fragment
                                                    key={`${index}_selected_slider`}
                                                >
                                                    <div
                                                        className="sliderTitle"
                                                        onClick={ev =>
                                                            this.showConfirm(
                                                                ev,
                                                                selected
                                                            )
                                                        }
                                                    >
                                                        <div className="labelForSlider">
                                                            {selected.causeLabel
                                                                .replace(
                                                                    "🇺🇳",
                                                                    dataConfig.emojiSDG
                                                                )
                                                                .replace(
                                                                    "SDG·",
                                                                    dataConfig.emojiSDGdot +
                                                                        " "
                                                                )}
                                                        </div>
                                                        <div className="infoIcon">
                                                            <Icon type="info-circle" />
                                                        </div>
                                                    </div>
                                                    <Sliders
                                                        step={this.state.step}
                                                        cause={selected}
                                                        onMouseUp={(
                                                            step,
                                                            selected,
                                                            triggerApi
                                                        ) =>
                                                            this.onMouseUp(
                                                                step,
                                                                selected,
                                                                triggerApi
                                                            )
                                                        }
                                                    />
                                                </React.Fragment>
                                            );
                                        }
                                    )}
                                </Panel>
                            )}
                        </Collapse>
                        <Button
                            onClick={this.resetApp}
                            type="primary"
                            size={"large"}
                            className={`background_reset XXbackground_${dbKey}`}
                            style={{
                                marginTop: "20px",
                                marginBottom: "100px",
                                marginLeft: "auto",
                                marginRight: "auto",
                                display: "block"
                            }}
                        >
                            Reset App
                        </Button>
                        <div
                            className="sidebarLinksDiv"
                            style={{ color: siteColor }}
                        >
                            <br />
                            <a
                                href="https://ImpactLabs.com"
                                title="Impact Labs"
                                style={{ color: siteColor }}
                            >
                                <br />
                                <br />
                                <div
                                    className="createLogoContainer"
                                    style={{
                                        background: siteColor,
                                        width: 20,
                                        marginLeft: "auto",
                                        marginRight: "auto"
                                    }}
                                >
                                    <img
                                        src="https://d3e1j90z5g3six.cloudfront.net/images/impact/labs_logo_white-alpha400x.png"
                                        style={{ padding: "0px 1px 2px 2px" }}
                                        alt="Impact Labs"
                                        width="20"
                                        height="auto"
                                    />
                                    <br />
                                </div>
                                <div style={{ marginTop: 4, marginBottom: 0 }}>
                                    IMPACT Labs
                                </div>
                            </a>
                            <br style={{ height: "1px" }} />
                            <span
                                className="sidebarLinks"
                                style={{ marginTop: 22 }}
                            >
                                <a
                                    href={aboutHtml}
                                    title="About Impact Labs"
                                    style={{ color: siteColor }}
                                >
                                    about
                                </a>
                            </span>{" "}
                            &nbsp;
                            <span className="sidebarLinks">
                                <a
                                    href={pressHtml}
                                    title="Impact Labs press"
                                    style={{ color: siteColor }}
                                >
                                    press
                                </a>
                            </span>{" "}
                            &nbsp;
                            <span className="sidebarLinks">
                                <a
                                    href={solutionsHtml}
                                    title="ESG Solutions: Tax optimized direct indexing"
                                    style={{ color: siteColor }}
                                >
                                    ESG&nbsp;solutions
                                </a>
                            </span>{" "}
                            &nbsp;
                            <span className="sidebarLinks">
                                <a
                                    href={taxHtml}
                                    title="Tax-optimized investing"
                                    style={{ color: siteColor }}
                                >
                                    tax
                                </a>
                            </span>
                        </div>
                        <hr />
                        <div className="versionFont">
                            <span style={{ color: siteColor }} />
                            <br />
                            v{dataConfig.codeVersion}
                        </div>
                        <Modal
                            style={{ maxWidth: "320px" }}
                            onCancel={this.onCancel}
                            visible={this.state.openCallToActionModal}
                            closable={false}
                            footer={[
                                <div className="centerThis">
                                    <Button
                                        key="ok1"
                                        className="optionSubmitButton"
                                        disabled={!isButtonDisabled}
                                        onClick={() =>
                                            this.onSaveCallToAction("Investor")
                                        }
                                        style={{
                                            background: siteColor,
                                            color: "white"
                                        }}
                                    >
                                        Individual Investor
                                    </Button>
                                    <Button
                                        key="ok2"
                                        className="optionSubmitButton"
                                        disabled={!isButtonDisabled}
                                        onClick={() =>
                                            this.onSaveCallToAction("FA")
                                        }
                                        style={{
                                            background: siteColor,
                                            color: "white"
                                        }}
                                    >
                                        Financial Advisor
                                    </Button>
                                    <Button
                                        key="ok3"
                                        className="optionSubmitButton"
                                        disabled={!isButtonDisabled}
                                        onClick={() =>
                                            this.onSaveCallToAction(
                                                "Institutional"
                                            )
                                        }
                                        style={{
                                            background: siteColor,
                                            color: "white"
                                        }}
                                    >
                                        Institutional Investor
                                    </Button>
                                    <Button
                                        key="ok4"
                                        className="optionSubmitButton"
                                        disabled={!isButtonDisabled}
                                        onClick={() =>
                                            this.onSaveCallToAction("Industry")
                                        }
                                        style={{
                                            background: siteColor,
                                            color: "white"
                                        }}
                                    >
                                        Industry Professional
                                    </Button>
                                    <Button
                                        key="ok5"
                                        className="optionSubmitButton"
                                        disabled={!isButtonDisabled}
                                        onClick={() =>
                                            this.onSaveCallToAction("Other")
                                        }
                                        style={{
                                            background: siteColor,
                                            color: "white"
                                        }}
                                    >
                                        Other: none of the above
                                    </Button>
                                </div>
                            ]}
                        >
                            <a
                                href={
                                    "https://ImpactLabs.com#" +
                                    dataConfig.dbUrlKey
                                }
                            >
                                <div className="tooltip">
                                    <span
                                        class="tooltiptext"
                                        style={{ whiteSpace: "nowrap" }}
                                    >
                                        <small>
                                            Visit ImpactLabs<small>.com</small>
                                        </small>
                                    </span>
                                    <div
                                        className="createLogoContainerNoMobile centerThis logoImage40 tooltip"
                                        style={{ background: siteColor }}
                                        alt="ImpactLabs.com"
                                        title="ImpactLabs.com"
                                        onClick="location.href='https://ImpactLabs.com'"
                                    >
                                        <div>
                                            <img
                                                src="https://d3e1j90z5g3six.cloudfront.net/images/impact/labs_logo_white-alpha400x.png"
                                                alt="Impact Labs"
                                                height="auto"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <h4
                                className="headerModals"
                                style={{
                                    textAlign: "center",
                                    color: siteColor
                                }}
                            >
                                {dataConfig.impactTitle}
                                <small>{dataConfig.ImpactTitleSmall}</small>
                                <sub>{dataConfig.ImpactTitleSub}</sub>
                                <br />
                            </h4>
                            <p
                                style={{
                                    textAlign: "center",
                                    marginTop: "6px",
                                    color: siteColor
                                }}
                            >
                                {dataConfig.impactSubtitle}
                                <br />
                                {dataConfig.impactSubtitle2}
                                <br />
                                {dataConfig.impactSubtitle3}
                                <br />
                            </p>
                            <p
                                style={{
                                    textAlign: "center"
                                }}
                            >
                                <br />

                                <span style={{ whiteSpace: "nowrap" }} />
                                <small>
                                    {userEmail ? 'We updated our website since you were first here. Please re-enter your info' : 'Please enter your email'} to continue:
                                </small>
                            </p>
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    marginTop: "0.3em"
                                }}
                            >
                                <Input
                                    className="centerThis"
                                    style={{ width: "49%" }}
                                    ref={this.myInputRefFirstName}
                                    placeholder="First name"
                                    type="text"
                                />
                                <Input
                                    className="centerThis"
                                    style={{
                                        width: "49%"
                                    }}
                                    ref={this.myInputRefLastName}
                                    placeholder="Last name"
                                    type="text"
                                />
                            </div>
                            <Input
                                className="centerThis"
                                style={{
                                    marginTop: "0.3em"
                                }}
                                ref={this.myInputRefPhone}
                                placeholder= "Phone"
                                type="phone"
                            />
                            <Input
                                className="centerThis"
                                style={{
                                    marginTop: "0.3em"
                                }}
                                ref={this.myInputRefEmail}
                                onChange={this.validateEmail}
                                placeholder="Email *"
                                type="email"
                            />
                            <br />
                            <Checkbox
                                className="centerThis"
                                onChange={this.toogleCheckbox}
                                checked={this.state.checkboxClicked}
                            >
                                <small>Subscribe for free</small>
                            </Checkbox>
                            <p
                                style={{
                                    textAlign: "center",
                                    marginBottom: "4px"
                                }}
                            />
                            <br />
                            <p
                                style={{
                                    textAlign: "center",
                                    marginBottom: "-15px"
                                }}
                            >
                                {isButtonDisabled ? (
                                    <span>select one:</span>
                                ) : (
                                    <span>
                                        <div
                                            style={{ cursor: "pointer" }}
                                            onClick={ev =>
                                                this.modalShowPerf(ev)
                                            }
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    text[
                                                        "confirmModal.modalShowPerf.impactText"
                                                    ]
                                            }}
                                        />
                                    </span>
                                )}
                            </p>
                            <div style={{ display: "none" }}>
                                <img
                                    src="https://d3e1j90z5g3six.cloudfront.net/images/impact/IA_Modern_Wealth_Management_00.png"
                                    alt="Impact Labs"
                                    height="auto"
                                />
                                <img
                                    src="https://d3e1j90z5g3six.cloudfront.net/images/other/USSIF_2018a.png"
                                    alt="Impact Labs"
                                    height="auto"
                                />
                                <img
                                    src="https://d3e1j90z5g3six.cloudfront.net/images/other/USSIF_2018alpha.png"
                                    alt="Impact Labs"
                                    height="auto"
                                />
                                <img
                                    src="img src='https://d3e1j90z5g3six.cloudfront.net/images/other/RetirementReadiness.jpg"
                                    alt="Impact Labs"
                                    height="auto"
                                />
                                <img
                                    src="img src='https://s3-us-west-2.amazonaws.com/impactstatic/images/gifs/slidersWeapons_blue.gif"
                                    alt="Impact Labs"
                                    height="auto"
                                />
                                <img
                                    src="img src='https://flowcharts.llnl.gov/content/assets/images/charts/Energy/Energy_2018_United-States.png"
                                    alt="Impact Labs"
                                    height="auto"
                                />
                                <img
                                    src="img src='https://www.pgpf.org/sites/default/files/0053_defense_comparison-full.gif"
                                    alt="Impact Labs"
                                    height="auto"
                                />
                                <img
                                    src="img src='https://images.procon.org/wp-content/uploads/sites/15/1990-us-obesity-map.jpg"
                                    alt="Impact Labs"
                                    height="auto"
                                />
                                <img
                                    src="img src='http://www.greeneatz.com/wp-content/uploads/2013/01/foods-carbon-footprint-7.gif"
                                    alt="Impact Labs"
                                    height="auto"
                                />
                                <img
                                    src="img src='https://d3e1j90z5g3six.cloudfront.net/images/other/GreenProfit_320x.png"
                                    alt="Impact Labs"
                                    height="auto"
                                />
                                <img
                                    src="img src='https://d3e1j90z5g3six.cloudfront.net/images/other/GreenProfit_320x.png"
                                    alt="Impact Labs"
                                    height="auto"
                                />
                                <img
                                    src="img src='https://d3e1j90z5g3six.cloudfront.net/images/other/GreenProfit_320x.png"
                                    alt="Impact Labs"
                                    height="auto"
                                />
                                <img
                                    src="img src='https://d3e1j90z5g3six.cloudfront.net/images/other/GreenProfit_320x.png"
                                    alt="Impact Labs"
                                    height="auto"
                                />
                                <img
                                    src="img src='https://d3e1j90z5g3six.cloudfront.net/images/other/GreenProfit_320x.png"
                                    alt="Impact Labs"
                                    height="auto"
                                />
                                <img
                                    src="img src='https://d3e1j90z5g3six.cloudfront.net/images/other/GreenProfit_320x.png"
                                    alt="Impact Labs"
                                    height="auto"
                                />
                            </div>
                        </Modal>
                    </CustomScrollbars>
                </div>
            </Auxiliary>
        );
    }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({ settings, sidebar }) => {
    const { navStyle, themeType, locale, pathname } = settings;
    return { navStyle, themeType, locale, pathname, sidebar };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getAllCauses,
            storeSelectedCauses,
            getTickersData,
            getPortfolioData,
            storeSelectedCause,
            reset,
            setGeoLocation
        },
        dispatch
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SidebarContent);
